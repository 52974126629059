<template>
  <v-list dense v-if="loadingFilters">
    <v-card width="100%" height="100vh" elevation="0">
      <v-container fill-height color="white">
        <v-row justify="center" align="center">
          <v-col cols="12" justify="center" align="center">
            <v-progress-circular
              :width="5"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
          <v-col cols="12" justify="center" align="center">
            <div>
              Obteniendo filtros ...
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-list>
  <v-list dense v-else>
    <v-list-item>
      <span class="headline">Filtros</span>
      <v-icon class="ml-2" color="secondary">mdi-tune</v-icon>
      <v-spacer />
      <v-btn icon @click="refreshPressed()">
        <v-icon class="pl-1" style="width: 100%;" small>mdi-refresh </v-icon>
      </v-btn>
    </v-list-item>
    <v-divider />
    <v-list-item-group>
      <!-- <v-list-item>
        <span class="headline">Filtrar por:</span>
      </v-list-item> -->
      <v-list-item v-if="role === 'admin'">
        <v-list-item-content class="pl-1">
          <v-btn
            v-if="loading"
            dark
            color="grey"
            @click="
              () => {
                showLeadsDuplicates = !showLeadsDuplicates;
              }
            "
          >
            Cargando ...
          </v-btn>
          <v-btn
            v-else
            dark
            :color="showLeadsDuplicates ? 'blue-grey darken-4' : 'grey'"
            @click="
              () => {
                showLeadsDuplicates = !showLeadsDuplicates;
              }
            "
          >
            Duplicados
          </v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="role === 'admin'" color="transparent">
        <v-list-item-content class="pl-1">
          <v-select
            dense
            :items="setContactBrokerNames(filtersSt.contacts)"
            label="Seleccionar broker"
            :menu-props="menuProps"
            v-model="filterBrokers"
          ></v-select>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="role === 'admin'" color="transparent">
        <v-list-item-content class="pl-1">
          <v-select
            dense
            :items="setContactBrexNames(filtersSt.externalBrokers)"
            label="Seleccionar broker Externo"
            :menu-props="menuProps"
            v-model="filterBrex"
          ></v-select>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="role !== 'marketing' || role !== 'marketingv2'"
        color="transparent"
      >
        <v-list-item-content
          class="pl-1"
          v-if="showDiscardedLeads || showDiscardedAndActiveLeads"
          @click="dialogPhasesHelper = true"
        >
          <v-select
            dense
            disabled
            :items="filtersSt.phases"
            label="Deshabilitado"
            item-value="data"
            :menu-props="menuProps"
            v-model="filterLeadStatus"
          ></v-select>
          <v-dialog v-model="dialogPhasesHelper" max-width="350">
            <v-card>
              <v-card-title class="title">
                Filtro por Fases deshabilitado.
              </v-card-title>

              <v-card-text>
                No puedes seleccionar una fase cuando el filtro "Mostrar
                Activos, Finalizados y Descartados" o Cuando el filtro "Mostrar
                solo descartados" esta habilitado.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text @click="dialogPhasesHelper = false">
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item-content>
        <v-list-item-content class="pl-1" v-else>
          <v-select
            dense
            :items="filtersSt.phases"
            label="Filtrar por fase"
            item-value="data"
            :menu-props="menuProps"
            v-model="filterLeadStatus"
          ></v-select>
        </v-list-item-content>
      </v-list-item>
      <v-list-item color="transparent">
        <v-list-item-content class="pl-1">
          <!-- Se incluye un botón para poder invertir la selección -->
          <v-autocomplete
            dense
            :items="setSourceName(filtersSt.sources)"
            label="¿Cómo nos encontró?"
            item-text="name"
            return-object
            multiple
            chips
            :menu-props="menuProps"
            v-model="filterHowDidContactUs"
          >
            <template v-slot:append-outer>
              <v-slide-x-reverse-transition mode="out-in">
                <v-icon
                  :key="`icon-${isEditing}`"
                  @click="
                    filterHowDidContactUs = invertSelection(
                      setSourceName(filtersSt.sources),
                      filterHowDidContactUs
                    )
                  "
                  v-text="isEditing ? 'mdi-find-replace' : 'mdi-find-replace'"
                ></v-icon>
              </v-slide-x-reverse-transition>
            </template>

            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ filterHowDidContactUs.length - 1 }} más)
              </span>
            </template>
          </v-autocomplete>
        </v-list-item-content>
      </v-list-item>
      <v-list-item color="transparent">
        <v-list-item-content class="pl-1">
          <v-autocomplete
            dense
            :items="setMediumName(filtersSt.mediums)"
            label="¿Cómo nos contactó?"
            item-text="name"
            return-object
            multiple
            chips
            :menu-props="menuProps"
            v-model="filterWhereDidContactUs"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ filterWhereDidContactUs.length - 1 }} más)
              </span>
            </template>
          </v-autocomplete>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="role !== 'broker'" color="transparent">
        <v-list-item-content class="pl-1">
          <v-select
            dense
            :items="filtersSt.zones"
            label="Llegó por"
            v-model="filterZones"
            multiple
            :menu-props="menuProps"
          ></v-select>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="role !== 'marketing' || role !== 'marketingv2'"
        color="transparent"
      >
        <v-list-item-content class="pl-1">
          <v-switch
            v-model="showDelayedLeads"
            flat
            color="error"
            label="Requieren atención"
          ></v-switch>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="role === 'marketing' || role === 'marketingv2'"
        color="transparent"
      >
        <v-list-item-content class="pl-1">
          <v-select
            v-model="searchCampaignFilter"
            dense
            :items="setCampaigns(filtersSt.campaigns)"
            label="Campaña"
            :menu-props="menuProps"
          ></v-select>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="role === 'marketing' || role === 'marketingv2'"
        color="transparent"
      >
        <v-list-item-content class="pl-1">
          <v-select
            v-model="searchConjuntoFilter"
            dense
            :items="setAdSets(filtersSt.adSets)"
            label="Conjunto"
            :menu-props="menuProps"
          ></v-select>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="role === 'marketing' || role === 'marketingv2'"
        color="transparent"
      >
        <v-list-item-content class="pl-1">
          <v-select
            v-model="searchAnuncioFilter"
            dense
            :items="setAds(filtersSt.ads)"
            label="Anuncio"
            :menu-props="menuProps"
          ></v-select>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="role === 'marketing' || role === 'marketingv2'"
        color="transparent"
      >
        <v-list-item-content class="pl-1">
          <v-select
            v-model="searchFormularioFilter"
            dense
            :items="setForms(filtersSt.forms)"
            label="Formulario"
            :menu-props="menuProps"
          ></v-select>
        </v-list-item-content>
      </v-list-item>
      <v-list-item color="transparent">
        <v-list-item-content>
          <v-select
            height="50px"
            v-model="filterDate"
            :items="dateFilters"
            label="Filtrar por fechas"
          >
            <template slot="item" slot-scope="{ item }">
              <v-btn text block @click="getDateRange(item)">
                {{ item }}
                <v-spacer></v-spacer>
              </v-btn>
            </template>
          </v-select>
          <div v-if="showRange">
            <v-menu
              left
              v-model="showDatePicker"
              :close-on-content-click="false"
              :close-on-click="false"
              transition="scale-transition"
              min-width="auto"
              offset-y
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Seleccciona un rango de fechas"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateRange"
                range
                locale="es"
                @input="filterDate = 'Personalizado'"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="saveDateRange(false, dateRange)"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    saveDateRange(true, dateRange),
                      (filterDate = 'Personalizado')
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item color="transparent">
        <v-list-item-content>
          <div>
            <v-skeleton-loader height="50" type="avatar">
              <v-card
                elevation="0"
                color="transparent"
                :style="
                  `
                  height: 70px;
                  background-image: url(${
                    filterProfiles
                      ? profileItems[indexSelected].srcImage
                      : profileItems[indexHover].srcImage
                  });
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  `
                "
              >
                <template>
                  <div v-for="(profile, index) in profileItems" :key="index">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        rounded="0"
                        :ripple="false"
                        :append="false"
                        :link="false"
                        class="tansparent"
                        color="transparent"
                        :height="index === 0 ? '14px' : '12px'"
                        :width="'100%'"
                        :elevation="hover ? 0 : 0"
                        @mouseover="indexHover = index"
                        @mouseleave="indexHover = 4"
                      >
                        <v-row
                          class="ma-0 pa-0"
                          @click="
                            () => {
                              filterProfiles = profile.value;
                              showProfileSelect = false;
                              showfilterProfiles = false;
                              indexSelected = index;
                            }
                          "
                        >
                          <v-icon
                            class="ml-14"
                            size="20"
                            v-if="hover && indexHover !== indexSelected"
                          >
                            mdi-chevron-right
                          </v-icon>
                          <v-icon
                            class="ml-14"
                            size="20"
                            v-if="
                              filterProfiles &&
                                indexSelected === index &&
                                indexSelected !== 4
                            "
                          >
                            mdi-chevron-right
                          </v-icon>
                          <v-icon
                            class="ml-14"
                            size="20"
                            v-else-if="!filterProfiles && index === 4"
                          >
                            mdi-chevron-right
                          </v-icon>
                        </v-row>
                      </v-card>
                    </v-hover>
                  </div>
                </template>
              </v-card>
            </v-skeleton-loader>
          </div>
        </v-list-item-content>
      </v-list-item>

      <v-list-item color="transparent">
        <v-list-item-content class="pl-1">
          <v-switch
            v-model="showDiscardedAndActiveLeads"
            flat
            color="secondary"
            label="Mostrar Activos, Finalizados y Descartados"
          ></v-switch>
        </v-list-item-content>
      </v-list-item>
      <v-list-item color="transparent">
        <v-list-item-content class="pl-1">
          <v-switch
            v-model="showDiscardedLeads"
            flat
            color="secondary"
            label="Mostrar solo Descartados"
          ></v-switch>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
import moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";
moment.locale("es");

export default {
  components: {
    // LeadDateRangePicker: () =>
    //   import("@/components/lead/LeadDateRangePicker.vue")
  },
  data: () => ({
    role: localStorage.getItem("user_role"),
    dialogPhasesHelper: false,
    loadingFilters: false,
    indexSelected: 4,
    indexHover: 4,
    today: new Date().toISOString().substr(0, 10),
    showProfileSelect: false,
    hasEnterUpdateProfile: false,
    emptyProfileImage: `${process.env.BASE_URL}termometro.gif`,
    profileItems: [
      {
        value: "A",
        srcImage: `${process.env.BASE_URL}50rojo-selec.png`,
        color: "red"
      },
      {
        value: "B",
        srcImage: `${process.env.BASE_URL}50naranja-selec.png`,
        color: "orange"
      },
      {
        value: "C",
        srcImage: `${process.env.BASE_URL}50amarillo-selec.png`,
        color: "yellow"
      },
      {
        value: "D",
        srcImage: `${process.env.BASE_URL}50azul-selec.png`,
        color: "blue"
      },
      {
        value: "",
        srcImage: `${process.env.BASE_URL}vacio-selec.png`,
        color: "blue"
      }
    ],
    filterDate: null,
    showRange: false,
    showDatePicker: false,
    dateRange: [],
    dateFilters: [
      "Ayer",
      "Hoy",
      "Ultimos 7 dias",
      "Ultimos 30 dias",
      "Duración Total",
      "Mes pasado",
      "Este mes",
      "Año actual",
      "Personalizado"
    ],
    leadHowDidContactUs: "",
    leadProfile: null,
    initActions: [],
    menuProps: {
      auto: true,
      bottom: true,
      offsetY: true,
      closeOnClick: true
    },
    isEditing: false
  }),
  watch: {
    endDateCreatedAt(newValue) {
      const initDate = moment(this.initDateCreatedAt);
      const endDate = moment(this.endDateCreatedAt);

      // en caso de que la fecha inicial sea posterior, poner igual a la fecha final
      if (moment(initDate).isAfter(endDate)) {
        this.date1 = newValue;
      }
    }
  },

  computed: {
    ...mapState({
      preferencesSt: state => state.preferences,
      filtersSt: state => state.leads.filters || {},
      loading: state => state.leads.loading,
      realEstateGroupLoading: state =>
        state.realEstateGroup.realEstateGroupLoading,
      realEstateGroup: state => state.realEstateGroup.realEstateGroup,
      filtersSelectedSt: state => state.leads.filtersSelected,
      filtersSelectedChangedSt: state => state.leads.filtersSelectedChanged
    }),
    dateRangeText() {
      return this.dateRange.join(" ~ ");
    },
    someFilterSelected: {
      get() {
        return this.$store.state.leads.filterSomeFilterSelected;
      },
      set(newValue) {
        this.$store.commit("leads/setfilterSomeFilterSelected", newValue);
      }
    },
    showLeadsDuplicates: {
      get() {
        return this.$store.state.leads.filterShowLeadsDuplicates;
      },
      set(newValue) {
        this.$store.commit("leads/setfilterShowLeadsDuplicates", newValue);
      }
    },
    showDiscardedLeads: {
      get() {
        return this.$store.state.leads.filterShowDiscarded;
      },
      set(newValue) {
        // al activar el filtro descartados, deshabilitar filtro requieren atención
        if (newValue) this.showDelayedLeads = false;
        this.$store.commit("leads/setFilterShowDiscarded", newValue);
      }
    },
    showDiscardedAndActiveLeads: {
      get() {
        return this.$store.state.leads.filterShowDiscardedAndActive;
      },
      set(newValue) {
        // al activar el filtro descartados, deshabilitar filtro requieren atenció
        this.$store.commit("leads/setFilterShowDiscardedAndActive", newValue);
      }
    },
    showDelayedLeads: {
      get() {
        return this.$store.state.leads.filterShowDelayedLeads;
      },
      set(newValue) {
        // al activar el filtro de requieren atención, deshabilitar filtro descartados
        this.$store.commit("leads/setFilterShowDelayed", newValue);
      }
    },
    showfilterProfiles: {
      get() {
        return this.$store.state.leads.showfilterProfiles;
      },
      set(newValue) {
        if (newValue) {
          //switch true
          this.filterProfiles = false;
          this.hasEnterUpdateProfile = false;
        } else {
          //switch falso
          this.hasEnterUpdateProfile = true;
          this.showProfileSelect = true;
        }
        //this.$store.commit("leads/setFilterProfiles", newValue);
      }
    },
    initDateCreatedAt: {
      get() {
        return this.$store.state.leads.filterInitDateCreateAt;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterInitDateCreateAt", newValue);
      }
    },
    endDateCreatedAt: {
      get() {
        return this.$store.state.leads.filterEndDateCreateAt;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterEndDateCreateAt", newValue);
      }
    },
    filterProfiles: {
      get() {
        return this.$store.state.leads.filterProfiles;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterProfiles", newValue);
      }
    },
    filterBrokers: {
      get() {
        return this.$store.state.leads.filterBrokers;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterBrokers", newValue);
      }
    },
    filterBrex: {
      get() {
        return this.$store.state.leads.filterBrex;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterBrex", newValue);
      }
    },
    filterLeadStatus: {
      get() {
        return this.$store.state.leads.filterLeadStatus;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterLeadStatus", newValue);
      }
    },
    filterHowDidContactUs: {
      get() {
        return this.$store.state.leads.filterHowDidContactUs;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterHowDidContactUs", newValue);
      }
    },
    filterWhereDidContactUs: {
      get() {
        return this.$store.state.leads.filterWhereDidContactUs;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterWhereDidContactUs", newValue);
      }
    },
    filterZones: {
      get() {
        return this.$store.state.leads.filterZones;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterZones", newValue);
      }
    },
    searchCampaignFilter: {
      get() {
        return this.$store.state.leads.filterCampaign;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterCampaign", newValue);
      }
    },
    searchConjuntoFilter: {
      get() {
        return this.$store.state.leads.filterConjunto;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterConjunto", newValue);
      }
    },
    searchAnuncioFilter: {
      get() {
        return this.$store.state.leads.filterAnuncio;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterAnuncio", newValue);
      }
    },
    searchFormularioFilter: {
      get() {
        return this.$store.state.leads.filterFormulario;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterFormulario", newValue);
      }
    }
  },
  mounted() {
    this.loadingFilters = true;
    this.fetchGetLeadsFiltersSt().then(value => {
      this.loadingFilters = false;
      this.initActions.push({
        text: "Desde el inicio",
        tooltip: "La fecha del primer Leads que ingreso al sistema.",
        date: value.firstDate,
        onClick: () => {
          return moment(value.firstDate).format();
        }
      });
    });
  },
  methods: {
    ...mapMutations({
      setLeadsTableFooterSt: "leads/setLeadsTableFooter",
      setFiltersSelectedSt: "leads/setFiltersSelected",
      setFiltersSelectedChangedSt: "leads/setFiltersSelectedChanged",
      setLeadsPaginationClearSt: "leads/setLeadsPaginationClear",
      setClearFiltersSt: "leads/setClearFilters"
    }),
    ...mapActions({
      fetchGetLeadsFiltersSt: "leads/fetchGetLeadsFilters",
      fetchLeadsleadsPaginationSt: "leads/fetchLeadsPagination",
      fetchGetLeadsKanbanByPhaseSt: "leads/fetchGetLeadsKanbanByPhase"
    }),
    openLeadDateRangeComponent() {
      this.$refs.refLeadDatePickerRange.initLeadDatePickerModal();
    },
    closeLeadDateRangeComponent(date) {
      if (date.length === 2) {
        this.saveDateRange(true, date);
      }
    },
    setSourceName(list) {
      return list.map(item => {
        return item.source;
      });
    },
    setMediumName(list) {
      return list.map(item => {
        return item.medium;
      });
    },
    setContactBrokerNames(contacts) {
      const list = contacts
        .filter(e => e.status !== 0)
        .map(({ name, _id }) => {
          return { text: name, value: _id };
        });
      list.push({ text: "Todos", value: "all" });
      return list;
    },
    setContactBrexNames(contacts) {
      const list = contacts.map(({ name, _id }) => {
        return { text: name, value: _id };
      });
      list.push({ text: "Todos", value: "all" });
      return list;
    },
    setCampaigns(items) {
      return items.filter(e => {
        return e !== "" && e.trim() !== "";
      });
    },
    setAdSets(items) {
      return items.filter(e => {
        return e !== "" && e.trim() !== "";
      });
    },
    setAds(items) {
      return items.filter(e => {
        return e !== "" && e.trim() !== "";
      });
    },
    setForms(items) {
      return items.filter(e => {
        return e !== "" && e.trim() !== "";
      });
    },
    mktFilter(mktString, param) {
      // comprobar que exista el string mkt
      if (!mktString) return " - ";
      let findParam = param;
      // solo el campo Campaña no tiene '|' al principio
      if (param !== "Campaña") findParam = `| ${param}`;
      // revisar que exista el parametro en el string mkt
      const index = mktString.indexOf(findParam);
      if (index < 0) return " - ";
      let startPos = index + param.length + 2;
      if (param !== "Campaña") startPos += 2; // aumentar otros 2 por separador '|'
      // encontrar el siguiente delimitador '|'
      const indexDelimiter =
        mktString.indexOf("|", startPos) > 0
          ? mktString.indexOf("|", startPos)
          : mktString.length;
      return mktString.substring(startPos, indexDelimiter).trim();
    },
    getDateRange(select) {
      switch (select) {
        case "Duración Total":
          this.dateRange = [
            this.getFirstDateLeadRegisteredYYYYMMDD(),
            this.getToDayDateYYYYMMDD()
          ];
          this.saveDateRange(true, this.dateRange);
          this.showRange = false;
          break;
        case "Ayer":
          this.dateRange = [
            this.getYesterdayDateYYYYMMDD(),
            this.getYesterdayDateYYYYMMDD()
          ];
          this.saveDateRange(true, this.dateRange);
          this.showRange = false;
          break;
        case "Hoy":
          this.dateRange = [
            this.getToDayDateYYYYMMDD(),
            this.getToDayDateYYYYMMDD()
          ];
          this.saveDateRange(true, this.dateRange);
          this.showRange = false;
          break;
        case "Ultimos 7 dias":
          this.dateRange = [
            this.getLastWeekDateYYYYMMDD(),
            this.getToDayDateYYYYMMDD()
          ];
          this.saveDateRange(true, this.dateRange);
          this.showRange = false;
          break;
        case "Ultimos 30 dias":
          this.dateRange = [
            this.getLast30DateYYYYMMDD(),
            this.getToDayDateYYYYMMDD()
          ];
          this.saveDateRange(true, this.dateRange);
          this.showRange = false;
          break;
        case "Mes pasado":
          this.dateRange = [
            this.getLastMonthStartDateYYYYMMDD(),
            this.getLastMonthEndDateYYYYMMDD()
          ];
          this.saveDateRange(true, this.dateRange);
          this.showRange = false;
          break;
        case "Este mes":
          this.dateRange = [
            this.getCurrentMonthStartDateYYYYMMDD(),
            this.getToDayDateYYYYMMDD()
          ];
          this.saveDateRange(true, this.dateRange);
          this.showRange = false;
          break;
        case "Año actual":
          this.dateRange = [
            this.getLastYearDateYYYYMMDD(),
            this.getToDayDateYYYYMMDD()
          ];
          this.saveDateRange(true, this.dateRange);
          this.showRange = false;
          break;
        case "Personalizado":
          this.dateRange = [
            this.getCurrentMonthStartDateYYYYMMDD(),
            this.getToDayDateYYYYMMDD()
          ];
          this.showRange = true;
          this.openLeadDateRangeComponent();
          break;
        default:
          this.dateRange = ["", ""];
          this.saveDateRange(false, this.dateRange);
          this.showRange = true;
          this.openLeadDateRangeComponent();
          break;
      }
    },
    getYesterdayDateYYYYMMDD() {
      let y = moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD");

      return y;
    },
    getToDayDateYYYYMMDD() {
      let d = moment().format("YYYY-MM-DD");
      return d;
    },
    getLast30DateYYYYMMDD() {
      let y = moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD");
      return y;
    },
    getLastWeekDateYYYYMMDD() {
      let y = moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      return y;
    },
    getCurrentMonthStartDateYYYYMMDD() {
      let y = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      return y;
    },
    getLastMonthStartDateYYYYMMDD() {
      let y = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      return y;
    },
    getLastMonthEndDateYYYYMMDD() {
      let y = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      return y;
    },
    getLastYearDateYYYYMMDD() {
      let y = moment()
        .startOf("year")
        .format("YYYY-MM-DD");
      return y;
    },
    getFirstDateLeadRegisteredYYYYMMDD() {
      let y = new moment(this.filtersSt.firstDate).format("YYYY-MM-DD");
      return y;
    },
    saveDateRange(dateChoosed, date) {
      if (dateChoosed) {
        date.sort((a, b) => {
          return new Date(a) - new Date(b);
        });
        this.dateRange = date;
        this.showDatePicker = false;
      } else {
        this.dateRange = [];
        this.showDatePicker = false;
      }
      if (this.dateRange.length == 2) {
        let init = moment(this.dateRange[0])
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format();
        let end = moment(this.dateRange[1])
          .utcOffset(0)
          .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
          .format();
        this.initDateCreatedAt = init;
        this.endDateCreatedAt = end;
      } else {
        this.dateRange = [
          moment().format("YYYY-MM-DD"),
          moment()
            .add(7, "days")
            .format("YYYY-MM-DD")
        ];
        this.initDateCreatedAt = moment(this.dateRange[0])
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format();
        this.endDateCreatedAt = moment(this.dateRange[1])
          .utcOffset(0)
          .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
          .format();
      }
    },
    refreshPressed() {
      this.someFilterSelected = false;
      this.initDateCreatedAt = "";
      this.endDateCreatedAt = "";
      // this.dateRangeText = []; computed no tiene setter
      this.dateRange = [];
      this.filterDate = "";
      this.showLeadsDuplicates = false;
      this.showDiscardedLeads = false;
      this.showDiscardedAndActiveLeads = false;
      this.showDelayedLeads = false;
      this.filterProfiles = "";
      this.filterBrokers = "";
      this.filterBrex = "";
      this.filterLeadStatus = null;
      this.filterHowDidContactUs = "";
      this.filterWhereDidContactUs = "";
      this.filterZones = "";
      this.searchCampaignFilter = "";
      this.searchConjuntoFilter = "";
      this.searchAnuncioFilter = "";
      this.searchFormularioFilter = "";
      this.showRange = false;
      this.setClearFiltersSt(true);
      if (this.preferencesSt && this.preferencesSt.showKanbanFirst) {
        this.refreshLeadsKanban();
      } else {
        this.refreshLeadsTable();
      }
    },
    refreshLeadsTable() {
      this.setFiltersSelectedSt({});
      this.setLeadsTableFooterSt(null);
      this.fetchLeadsleadsPaginationSt({
        filter: this.filtersSelectedSt,
        page: 0,
        limit: 10,
        footer: this.leadsTableFooterSt
      });
    },
    refreshLeadsKanban() {
      this.setFiltersSelectedSt({});
      this.setLeadsTableFooterSt(null);
      this.fetchGetLeadsKanbanByPhaseSt({
        filter: this.filtersSelectedSt,
        page: 0,
        phases: this.filtersSt.phases
      });
    },
    invertSelection(items, selected) {
      this.isEditing = !this.isEditing;
      let filter = items.filter(e => !selected.includes(e));
      return filter;
    }
  }
};
</script>
<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.line-bottom {
  margin-left: 60px;
  border-bottom: 1px solid rgb(199, 199, 199) !important;
}
.icon-line {
  margin-bottom: 100px !important;
  padding-bottom: 100px !important;
}
</style>
